import React from 'react'
import Support from '../Assets/Support.svg'
import card2 from '../Assets/card2.jpg'
import video1 from '../Assets/video1.mp4'

const SectionFive = () => {
    return (
        <>
            <div className="container-fluid" style={{ backgroundColor: '#7FDAD2', padding: '100px' }}>
            </div>
            <div className="container-fluid p-3 mb-5" style={{borderBottom:'3px solid #7FDAD2'}}>
                <div className="container" style={{ backgroundColor: '#f7f7f7', marginTop: '-130px', borderRadius: '10px' }}>
                    <div className="row">
                        <div className="col-md-6 p-5">
                            <div className="col"><img src={Support} alt="" style={{ height: '50px' }} /></div>
                            <div className="col"><h4><b>Connect with an expert</b></h4></div>
                            <div className="col"><p>Understand how Tanla can transform your digital<br /> interactions. Our team is ready to help.</p></div>
                            <div className="col"><button className='btn btn-outline-primary rounded-pill read_more' style={{ padding: '9px', width: '200px' }}><b>Contact us</b></button></div>
                        </div>
                        <div className="col-md-6">
                            <img src={card2} alt="" style={{ width: '450px' }} />
                        </div>
                    </div>

                </div>
                <div className="container">
                    <div className="row pt-2 pb-2">
                        <div className="col-md-6 p-5">
                            <video src={video1} style={{ width: "100%", borderRadius: '10px' }} controls></video>
                        </div>
                        <div className="col-md-6 p-5">
                            <h3>Embracing Gen AI in everything we do</h3>
                            <p style={{ fontSize: '20px' }}>Create Gen AI native communications through our intelligent platform, featuring a versatile AI fabric that underlies our products for your digital interactions.</p>
                            <div className="col"><button className='btn btn-outline-primary rounded-pill read_more' style={{ padding: '9px', width: '200px' }}><b>Explore Wise Albert</b></button></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionFive
