import React from 'react'
import img1 from '../Assets/img1.png'

const SectionOne = () => {
    return (
        <>
            <div className="container-fluid cards_parent_backgorund">
                <div className="row">
                    <div className="col-md-12 pb-4 pt-4">
                        <h3>Trusted by over 2,000 enterprises spanning various industries, including leading tech giants, social media networks, and esteemed players in BFSI.</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 mt-2">
                        <div className="card p-2 mb-5">
                            <div className="card_header">
                                <span style={{ fontSize: '28px' }}><b>24/30</b></span><br />
                                <span>Top BFSI companies</span>
                            </div>
                            <div className="card_footer">
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px', marginBottom: '20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px', marginBottom: '20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px', marginBottom: '20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px', marginBottom: '20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px', marginBottom: '20px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-2">
                        <div className="card p-2">
                            <div className="card_header">
                                <span style={{ fontSize: '28px' }}><b>5/5</b></span><br />
                                <span>Global tech giants</span>
                            </div>
                            <div className="card_footer">
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-2">
                        <div className="card p-2">
                            <div className="card_header">
                                <span style={{ fontSize: '28px' }}><b>12/15</b></span><br />
                                <span>Top digital native brands</span>
                            </div>
                            <div className="card_footer">
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-2">
                        <div className="card p-2">
                            <div className="card_header">
                                <span style={{ fontSize: '28px' }}><b>Largest</b></span><br />
                                <span>Government partner</span>
                            </div>
                            <div className="card_footer">
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                                <img src={img1} alt='img1' style={{ width: '100px', height: '20px', margin: '8px' , marginBottom:'20px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionOne
