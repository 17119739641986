import React from 'react'
import { Link } from 'react-router-dom'
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";



const Footer = () => {
    return (
        <>
            <div className="container">
                <div className="row pt-5">
                    <div className="col-md-2">
                        <h4>Company</h4>
                        <p><Link className="link_style" to="/about">About</Link></p>
                        <p> <Link className="link_style" to="/careers">Careers</Link></p>
                        <p> <Link className="link_style" to="/leadership">Leadership</Link></p>
                        <p><Link className="link_style" to="/investors">Investors</Link></p>
                        <p><Link className="link_style" to="/esg">ESG</Link></p>
                    </div>
                    <div className="col-md-2">
                        <h4>Resources</h4>
                        <p><Link className="link_style" to="/casestudies">Case Studies</Link></p>
                        <p><Link className="link_style" to="/blog">Blog</Link></p>
                        <p><Link className="link_style" to="/newsroom">Newsroom</Link></p>
                    </div>
                    <div className="col-md-4">
                        <h4>Values</h4>
                        <p><Link className="link_style" to="/casestudies">No Scam, No Spam</Link></p>
                        <p><Link className="link_style" to="/casestudies">Data Privacy, Data Security</Link></p>
                        <p><Link className="link_style" to="/casestudies">Ubiquitous Accessibility</Link></p>
                        <p><Link className="link_style" to="/casestudies">100% Transparency</Link></p>
                        <p><Link className="link_style" to="/casestudies">Purposeful Collaboration</Link></p>
                    </div>
                    <div className="col-md-4">
                        <h4>Latest from Tanla</h4>
                        <p>Be the first to know about latest developments and news from Tanla by subscribing to our email alert.</p>
                        <label htmlFor="email"><b>Email*</b></label><br/>
                        <input className='email_input' type="email"/><br />
                        <div className="row">
                            <div className="col d-flex" style={{justifyContent:'flex-end'}}>
                            <button type="button" class="btn btn-dark mt-1 rounded-pill">Subscribe</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col p-4 d-flex" style={{justifyContent:'space-between'}}>
                                <Link to={'#'}><FaLinkedin style={{ color: 'black' , fontSize:'25px' }} /></Link>
                                <Link to={'#'}><FaXTwitter style={{ color: 'black'  , fontSize:'25px' }} /></Link>
                                <Link to={'#'}><FaYoutube style={{ color: 'black'  , fontSize:'25px' }} /></Link>
                                <Link to={'#'}><FaInstagram style={{ color: 'black'  , fontSize:'25px' }} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 d-flex" style={{ gap: '20px' }}>
                        <Link className="link_style" to="/termsandcondition">Terms and Condition</Link>
                        <Link className="link_style" to="/sitemap">Sitemap</Link>
                        <Link className="link_style" to="/privacy">Privacy</Link>
                    </div>
                    <div className="col-md-6 footer_content">
                        <p>Copyright © 2024 Tanla. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer