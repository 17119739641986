import React, { useState } from 'react';
import videos from '../Assets/video2.mp4'

const SectionSix = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayButtonClick = () => {
        setIsPlaying(true);
    };
    return (
        <div className="container-fluid bg_video">
            <video autoPlay loop muted className="bg_video">
                <source src={videos} type="video/mp4" />
            </video>
            <div className="row">
                <div className="content_div">
                    <h3>Shaping the world of digital interactions</h3>
                    <p style={{ fontSize: '20px' }}>Drive innovation that empowers consumers and enables companies. Leverage our cutting-edge technologies, deep domain expertise, and strong ecosystem partnerships to create a new communication paradigm.</p>
                    <button className='watch_video_btn' onClick={handlePlayButtonClick}>Watch Video</button>
                </div>

            </div>

            {isPlaying && (
               <div className="opacityD">
                 <video controls>
                    <source src={videos} type="video/mp4" />
                </video>
               </div>
            )}

        </div>
    );
};

export default SectionSix;
