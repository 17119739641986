import React from 'react'
import desktop from '../Assets/desktop.svg'
import { FaArrowRightLong } from 'react-icons/fa6'

const SectionFour = () => {
    return (
        <>
            <div className="container">
                <div className="row text-center mb-3">
                    <h3><b>Explore our role in reshaping the world of digital interactions</b></h3>
                </div>
                <div className="row pb-5 pt-3">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-1">
                                <img src={desktop} alt="" />
                            </div>
                            <div className="col-11">
                                <h4>Discover Wisly  <FaArrowRightLong /></h4>
                                <p>Trust our plateform of plateforms to unify your digital interactions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-1">
                                <img src={desktop} alt="" />
                            </div>
                            <div className="col-11">
                                <h4>Discover Wisly  <FaArrowRightLong /></h4>
                                <p>Trust our plateform of plateforms to unify your digital interactions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-1">
                                <img src={desktop} alt="" />
                            </div>
                            <div className="col-11">
                                <h4>Discover Wisly  <FaArrowRightLong /></h4>
                                <p>Trust our plateform of plateforms to unify your digital interactions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionFour
