import React from 'react'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree'
import SectionFour from './SectionFour'
import SectionFive from './SectionFive'
import SectionSix from './SectionSix'

const Home = () => {
  return (
    <>
    <SectionSix/>
    <SectionFive/>
    <SectionFour/>
    <SectionThree/>
    <SectionTwo/>
     <SectionOne/>
    </>
  )
}

export default Home
