import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './Style.css'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Components/Home'

const App = () => {
  return (
    <>
      <Router>
        <Header/>
        <Routes>
            <Route path='/' element={<Home/>}/>
        </Routes>
        <Footer/>
      </Router>
    </>
  )
}

export default App
