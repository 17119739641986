import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import card1 from '../Assets/card1.jpg'

const SectionThree = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 d-flex" style={{ justifyContent: 'space-between' }}>
                        <h3><b>What's new at Tanla</b></h3>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div class="card what_card">
                        <img src={card1} alt=""/>
                                <div class="card-body">
                                    <p class="card-title">CASE STUDY</p>
                                    <h5 class="card-title"><b>Celebrating tech entrepreneurship</b></h5>
                                    <p class="card-text">Find out the inspiration behind LBS's decision to pursue Tanla's story.</p>
                                    <Link to="" className='read_more_btn'>Read more <FaArrowRightLong /></Link>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div class="card what_card">
                        <img src={card1} alt=""/>
                                <div class="card-body">
                                    <p class="card-title">CASE STUDY</p>
                                    <h5 class="card-title"><b>Celebrating tech entrepreneurship</b></h5>
                                    <p class="card-text">Find out the inspiration behind LBS's decision to pursue Tanla's story.</p>
                                    <Link to="" className='read_more_btn'>Read more <FaArrowRightLong /></Link>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div class="card what_card">
                        <img src={card1} alt=""/>
                                <div class="card-body">
                                    <p class="card-title">CASE STUDY</p>
                                    <h5 class="card-title"><b>Celebrating tech entrepreneurship</b></h5>
                                    <p class="card-text">Find out the inspiration behind LBS's decision to pursue Tanla's story.</p>
                                    <Link to="" className='read_more_btn'>Read more <FaArrowRightLong /></Link>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                    <div class="card what_card">
                        <img src={card1} alt=""/>
                                <div class="card-body">
                                    <p class="card-title">CASE STUDY</p>
                                    <h5 class="card-title"><b>Celebrating tech entrepreneurship</b></h5>
                                    <p class="card-text">Find out the inspiration behind LBS's decision to pursue Tanla's story.</p>
                                    <Link to="" className='read_more_btn'>Read more <FaArrowRightLong /></Link>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionThree
