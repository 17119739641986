import React from 'react'
import { Link } from 'react-router-dom'
import { FaArrowRightLong } from "react-icons/fa6";
import img2 from '../Assets/img2.jpg'

const SectionTwo = () => {
    return (
        <>
            <div className="container p-5">
                <div className="row">
                    <div className="col-md-12 d-flex" style={{justifyContent:'space-between'}}>
                        <h3><b>Explore our success stories</b></h3>
                        <Link to="" className='viewAll_button'>View All <FaArrowRightLong /></Link>
                    </div>
                    <hr/>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <img src={img2} alt="" height="350px"/>
                    </div>
                    <div className="col-md-6">
                        <h3><b>Protecting 1 billion+ users from scams through AI/ML</b></h3>
                        <p style={{fontSize:'20px'}}>Combatting the rising issue of phishing attacks, Wisely ATP has been recognised as a global leader in scam prevention with a staggering 99%+ efficacy rate powered by 4 patent pending engines and a differentiated ecosystem play by partnering with leading tech giants such as Google and WhatsApp. It is the world's first end-to-end AI/ML based anti SMS-phishing platform that identifies and prevents scams in real-time, helping eliminate the source.</p>
                        <button className='btn btn-outline-primary rounded-pill read_more' style={{padding:'9px 18px'}}><b>Read More</b></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionTwo
